import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class HighlightService {

  public onHighlight = new Subject<number>();
  public onReset = new Subject();

  constructor() { }

  /**
   * call to trigger certain highlight
   * @param {number} id
   */
  public highlightRoute(id: number): void {
    this.onHighlight.next(id);
  }

  /**
   * resets all current highlights
   */
  public resetHighlights(): void {
    this.onReset.next();
  }

}
