import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {Router} from "@angular/router";
import {DataService} from "../data.service";

@Component({
  selector   : 'app-search',
  templateUrl: './search.component.html',
  styleUrls  : ['search.component.scss'],
  providers  : []
})
export class SearchComponent implements OnInit {

  // services
  private data_service: DataService;

  // privates
  private router;
  private ready: boolean;

  // search
  public search_results: any[];
  public results_collapsed = true;

  // view elements
  @ViewChild('searchBox') private searchbar: ElementRef;

  /**
   * c'tor
   * @param router
   * @param ds
   */
  constructor(router: Router, ds: DataService) {
    this.router = router;
    this.data_service = ds;
  }

  /**
   * init lifecycle hook
   */
  ngOnInit() {

    if (this.data_service.data_ready) {
      this.ready = true;
    } else {
      this.data_service.onDBLoaded.subscribe(() => {
        this.ready = true;
      });
    }

  }


  /**
   * perform the search
   * @param {string} term
   */
  public search(term: string): void {

    if (this.ready) {

      const data = this.data_service.getDatabase()["rows"];
      const filtered = data.filter((item) => {
          if (item["name"].toUpperCase().startsWith(term.toUpperCase())) {
            return item;
          }
        }
      );

      this.search_results = filtered;
    }


  }


  /**
   * open detail view and put current selected persons name into searchbar
   * @param {number} id
   * @param {string} firstname
   * @param {string} lastname
   */
  public openDetailView(id: number, firstname: string, lastname: string): void {

    // put current name into search bar
    this.searchbar.nativeElement.value = firstname + " " + lastname;

// create link and navigate to detail view
    const link = ['detail', id];
    this.router.navigate(link);
  }


  /**
   * if clicked select text in searchbar
   */
  public selectInputTextOnClick(): void {
    this.searchbar.nativeElement.setSelectionRange(0, this.searchbar.nativeElement.value.length);
  }


  /**
   * collapse result div
   */
  public collapseResultDiv(): void {
    this.results_collapsed = true;
  }



  /**
   * if there is content to search for --> expand the result box
   * @param {string} content
   */
  public expandResultDiv(content: string): void {
    if (!content) {
      this.results_collapsed = true;
    } else {
      this.results_collapsed = false;
    }
  }


  /**
   * handle keydown events
   * @param event
   */
  public handleKeydown(event: KeyboardEvent): void {
    // if 'ESC' pressed
    if (event.keyCode === 27
    ) {
      this.searchbar.nativeElement.value = "";
      this.collapseResultDiv();
    }
  }

}
