import {Component, OnInit, ViewChild, ElementRef, ViewChildren, AfterViewInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from "@angular/router";
import {DataService} from "../data.service";
import {logError} from "../utils/project.utilities";
import {HighlightService} from "../highlight.service";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  private router: Router;
  private route: ActivatedRoute;
  private data_service: DataService;
  private highlight_service: HighlightService;

  public nav_elements: any[];

  @ViewChild("navbarlinks") private nav_links: ElementRef;

  /**
   * c'tor
   * @param router
   * @param ds
   */
  constructor(router: Router, route: ActivatedRoute,
              ds: DataService, hs: HighlightService) {
    this.router = router;
    this.route = route;
    this.data_service = ds;
    this.highlight_service = hs;
  }

  /**
   * on init lifecycle hook
   */
  ngOnInit() {

    if (this.data_service.pages_ready) {
      this.initializeSitesObject();
      this.subscribeToRouterEvents();
      this.highlight_service.onHighlight.subscribe((id) => {
        this.resetActiveStates();
        this.markLinkActive(id);
      });
    } else {
      this.data_service.onPagesLoaded.subscribe(() => {
        this.initializeSitesObject();
        this.subscribeToRouterEvents();
        this.highlight_service.onHighlight.subscribe((id) => {
          this.resetActiveStates();
          this.markLinkActive(id);
        });
      }, logError);
    }


  }

  /**
   * what the function name says
   */
  private subscribeToRouterEvents(): void {


  }

  /**
   * initialize object containing information and states of navigation elements
   */
  private initializeSitesObject(): void {

    const sites = this.filterPagesInNavigation(this.data_service.getPages());
    const navigation_elements = [];
    let counter = 0;
    for (const item of sites) {
      const temp = {};
      temp["id"] = counter;
      ++counter;
      temp["title"] = item["title"];
      temp["link"] = item["content_url"];
      temp["active"] = false;
      navigation_elements.push(temp);
    }

    this.nav_elements = navigation_elements;
  }


  /**
   * filter pages to be displayed in navigation
   * @param data
   * @returns {any}
   */
  private filterPagesInNavigation(data): any {
    const foo = [];
    for (const item of data.pages) {
      if (item.in_nav) {
        foo.push(item);
      }
    }

    return foo;
  }


  /**
   * navigate to selected link
   * @param item
   */
  public gotoLink(item: any, id: number): void {

    this.resetActiveStates();
    this.markLinkActive(id);

    this.router.navigate([item]);
  }

  /**
   * reset active state of nav elements
   */
  public resetActiveStates(): void {
    for (const item of this.nav_elements) {
      item["active"] = false;
    }
  }

  /**
   * mark nav element active
   * @param {number} id
   */
  public markLinkActive(id: number): void {
    for (const item of this.nav_elements) {
      if (item["id"] === id) {
        item["active"] = true;
      }
    }
  }

}
