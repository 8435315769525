import {Injectable} from '@angular/core';

@Injectable()
export class HistoryService {

  private last_query: string;

  /**
   * c'tor
   */
  constructor() {
  }

  /**
   * backup last query
   * @param query
   */
  public backup(query: string): void {
    this.last_query = query;
  }

  /**
   * restore last query
   * @return {string}
   */
  public restore(): string {
    return this.last_query;
  }

}
