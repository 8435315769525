import {Component, OnInit} from '@angular/core';
import {DataService} from "../data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {isNullOrUndefined} from "util";
import {HighlightService} from "../highlight.service";

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {

  // privates
  private data_service: DataService;
  private route: ActivatedRoute;
  private router: Router;
  private highlight_service: HighlightService;
  private pages = [];

  // publics
  public structure = [];
  public include_file: string;
  public ready = false;


  /**
   * c'tor
   * @param {DataService} ds
   * @param {ActivatedRoute} route
   * @param {Router} router
   */
  constructor(ds: DataService, route: ActivatedRoute,
  router: Router, hs: HighlightService) {
    this.data_service = ds;
    this.route = route;
    this.router = router;
    this.highlight_service = hs;
  }


  /**
   * on init lifecycle hook
   */
  ngOnInit() {

    // (re)initialize component on parameter change
    this.route.params.subscribe(() => {
      this.initialize();
    });

  }

  /**
   * initialize component
   */
  private initialize(): void {

    let page = "";
    this.route.params.forEach((param) => {
      page = param["title"];
    });

    if (this.data_service.pages_ready) {

      this.pages = this.data_service.getPages()["pages"];
      if (!isNullOrUndefined(page)) {


        this.initializeSubpageStructure();
        if (this.findPageByLinkName(page)) {
          this.ready = true;
        }

      }

      this.highlight_service.highlightRoute(1);

    } else {
      this.data_service.onPagesLoaded.subscribe(() => {

        this.pages = this.data_service.getPages()["pages"];
        if (!isNullOrUndefined(page)) {


          this.initializeSubpageStructure();
          if (this.findPageByLinkName(page)) {
            this.ready = true;
          }

        }

        this.highlight_service.highlightRoute(1);

      });
    }

  }

  private initializeSubpageStructure(): void {
    const pages = this.data_service.getPages();

    const child_list = [];
    for (const item of pages["pages"]) {

      if (item.hasOwnProperty("children")) {
        for (const child of item["children"]) {
          const temp = {};
          temp["title"] = child["title"];
          temp["url"] = child["url"];
          temp["active"] = false;
          child_list.push(temp);
        }
      }

    }

    this.structure = child_list;
  }

  /**
   * find page by a given name
   * @param {string} name
   */
  private findPageByLinkName(name: string): boolean {

    this.resetActiveStates();

    // search in top level
    for (const item of this.pages) {
      if (item["url"] === name) {
        this.include_file = item["url"];
        return true;
      }
    }

    // continue to search in page structure
    for (const item of this.structure) {
      if (item["url"] === name) {
        this.markActive(item["url"]);
        this.include_file = item["url"];
        return true;
      }
    }


    return false;

  }


  /**
   * route to the correct content
   * @param {string} url
   */
  public routeToContent(url: string): void {

    this.resetActiveStates();
    this.markActive(url);

    this.router.navigate(["pages", url]);
  }


  private markActive(url: string): void {
    for (const item of this.structure) {
      if (item["url"] === url) {
        item["active"] = true;
      }
    }
  }


  /**
   * reset active states
   */
  private resetActiveStates(): void {
    for (const item of this.structure) {
      item["active"] = false;
    }
  }

}
