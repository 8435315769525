import {Component, OnInit} from '@angular/core';
import {DataService} from "../data.service";
import {ActivatedRoute} from "@angular/router";
import {isNullOrUndefined} from "util";
import {HighlightService} from "../highlight.service";

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {

  private data_service: DataService;
  private route: ActivatedRoute;
  private highlight_service: HighlightService;

  public include_file: string;
  public ready = false;

  /**
   * c'tor
   */
  constructor(ds: DataService, route: ActivatedRoute, hs: HighlightService) {
    this.data_service = ds;
    this.route = route;
    this.highlight_service = hs;
  }

  /**
   * on init
   */
  ngOnInit() {

    this.route.params.subscribe(() => {
      this.initialize();
    });

  }

  /**
   * (re)initialize component
   */
  private initialize(): void {

    let parent = "";
    this.route.params.forEach((param) => {
      parent = param["title"];
    });


    if (this.data_service.pages_ready) {

      if (!isNullOrUndefined(parent)) {
        if (this.findPageByLinkName(parent)) {
          console.log(this.include_file);
          this.ready = true;
        }
      }

      if (parent.startsWith("dank")) {
        this.highlight_service.highlightRoute(2);
      }

    } else {
      this.data_service.onPagesLoaded.subscribe(() => {
        if (!isNullOrUndefined(parent)) {
          if (this.findPageByLinkName(parent)) {
            console.log(this.include_file);
            this.ready = true;
          }
        }

        if (parent.startsWith("dank")) {
          this.highlight_service.highlightRoute(2);
        }
      });
    }

  }

  /**
   * find page by a given name
   * @param {string} name
   */
  private findPageByLinkName(name: string): boolean {

    const data = this.data_service.getPages();

    for (const item of data["pages"]) {
      if (name === item["url"]) {
        this.include_file = item["url"];
        return true;
      }
    }

    return false;
  }


}
