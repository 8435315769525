import {Component, OnInit, ViewChild} from '@angular/core';
import {DataService} from "./data.service";
import {print_on} from "./utils/project.utilities";
import {NavigationComponent} from "./navigation/navigation.component";
import {NavigationStart, Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  private data_service: DataService;

  constructor(ds: DataService) {
    this.data_service = ds;
  }

  ngOnInit() {

  }

}
