/**
 * typing print is easier than console.log
 */
export function print_on(...args: any[]): void {
  console.log(args);
}

/**
 * log error message
 * @param error
 */
export function logError(error: any): void {
    console.log(error);
}
