import {Component, OnInit} from '@angular/core';
import {DataService} from "../data.service";
import {PaginationInstance} from "ngx-pagination";
import {ActivatedRoute, Router} from "@angular/router";
import {isNullOrUndefined} from "util";
import {HistoryService} from "../history.service";
import {HighlightService} from "../highlight.service";

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  // services
  private data_service: DataService;
  private history_service: HistoryService;
  private hightlight_service: HighlightService;

  // routing
  private router: Router;
  private route: ActivatedRoute;

  // data
  public data: any[];

  // further
  public ready = false;
  public starting_letters: Object[];
  public deportations: Object[];
  public show_pagination = true;

  // applyFilter option
  public selected_letter = "*";

  // pagination settings
  public pagination_config: PaginationInstance = {
    id: 'custom',
    itemsPerPage: 20,
    currentPage: 1,
  };


  /**
   * c'tor
   * @param ds
   * @param router
   * @param route
   * @param hist
   */
  constructor(ds: DataService, router: Router, route: ActivatedRoute, hist: HistoryService, hs: HighlightService) {
    this.data_service = ds;
    this.router = router;
    this.route = route;
    this.history_service = hist;
    this.hightlight_service = hs;
  }


  /**
   * on init lifecycle hook
   */
  ngOnInit() {

    let parameter = {};
    this.route.params.forEach(params => {
      parameter = params;
    });

    // start by checking for data
    if (this.data_service.data_ready) {

      this.hightlight_service.highlightRoute(0);

      if (isNullOrUndefined(parameter["action"])) {
        this.initializeDefaultList();
      } else if (parameter["action"] === "r") {
        this.restoreListViewFromHistory();
      } else if (parameter["action"] === "f") {
        this.initializeListByParameters(parameter);
      }


    } else {

      this.data_service.onDBLoaded.subscribe(() => {

        this.hightlight_service.highlightRoute(0);

        if (isNullOrUndefined(parameter["action"])) {
          this.initializeDefaultList();
        } else if (parameter["action"] === "r") {
          this.restoreListViewFromHistory();
        } else if (parameter["action"] === "f") {
          this.initializeListByParameters(parameter);
        }

      });

    }
  }


  /**
   * initialize default list
   */
  private initializeDefaultList(): void {
    // temp data
    this.data = this.data_service.getDatabase()["rows"];
    this.initializeDeportations(this.data);
    this.initializeStartingLetterList(this.data);
  }


  /**
   * initialize list by parameters
   */
  private initializeListByParameters(params: {}): void {

    this.initializeDeportations(this.data_service.getDatabase()["rows"], false);

    const deportation_ids = params["deportations"].split(" ");
    for (const item of this.deportations) {
      for (const id of deportation_ids) {
        if (item["id"] === +id) {
          item["selected"] = true;
        }
      }
    }
    this.updateAvailableLetterList();

    this.pagination_config.currentPage = +params["page"];
    this.selected_letter = params["letter"].toUpperCase();

    this.resetLetterStates(this.selected_letter);
    this.applyFilter();
  }


  /**
   * restores the list by accessing the last stored filter query
   */
  private restoreListViewFromHistory(): void {

    const query_string = this.history_service.restore();
    if (isNullOrUndefined(query_string) || query_string === "") {
      this.initializeDefaultList();
    } else {
      this.initializeDeportations(this.data_service.getDatabase()["rows"], false);
      this.restoreMemberFromQueryString(query_string);
      this.applyFilter(query_string);
      this.updateAvailableLetterList();
      this.resetLetterStates(this.selected_letter);
    }

  }


  /**
   * restore members from given query string
   */
  private restoreMemberFromQueryString(query: string): void {

    const temp = query.split("?");
    const deportation_names = temp[0].split("&");
    for (const item of this.deportations) {
      for (const name of deportation_names) {
        if (item["deportation"] === name) {
          item["selected"] = true;
        }
      }
    }

    this.selected_letter = temp[1].toUpperCase();
    this.pagination_config.currentPage = +temp[2];
  }


  /**
   * applyFilter the available deportations
   * @param data
   * @param selected
   * @return {Array}
   */
  private initializeDeportations(data: any, selected = true): void {
    const temp: string[] = [];
    for (const item of data) {
      temp.push(item["category"]);
    }

    // count number of occurrences in the array
    const count = {};
    temp.forEach(item => {
      count[item] = (count[item] || 0) + 1;
    });

    // create list containing deportations
    // add id's
    const final = [];
    let id_counter = 0;

    for (const key of Object.keys(count)) {
      const tmp: {} = {};
      tmp["deportation"] = key;
      tmp["count"] = count[key];
      tmp["id"] = id_counter;
      tmp["selected"] = selected;
      ++id_counter;

      final.push(tmp);
    }

    this.deportations = final;
  }


  /**
   * initialize the amount of available starting letters
   * @param data
   */
  private initializeStartingLetterList(data: any): void {

    // get list of all starting letters
    let current_letters: string[] = ["*"];
    for (const item of data) {
      current_letters.push(item.name[0]);
    }

    const final_alphabet = [];
    current_letters = Array.from(new Set(current_letters));
    for (const letter of current_letters) {
      const temp = {};
      temp["letter"] = letter.toUpperCase();
      if (letter === "*") {
        temp["state"] = true;
      } else {
        temp["state"] = false;
      }
      final_alphabet.push(temp);
    }


    // remove duplicates and set global value
    this.starting_letters = final_alphabet;
  }


  /**
   * deportation selected
   * @param value
   * @param state
   */
  public deportationSelected(value: string, state: boolean): void {
    for (const item of this.deportations) {
      if (item["deportation"] === value) {
        // console.log("changing state of", item["deportation"], "from", item["selected"], "to", state);
        item["selected"] = state;
      }
    }

    // update letter list
    this.updateAvailableLetterList();
    this.applyFilter();
  }


  /**
   * letter selected
   * @param value
   */
  public letterSelected(value: string): void {
    this.resetLetterStates(value);
    this.selected_letter = value;
    this.pagination_config.currentPage = 1;
    this.applyFilter();
  }


  /**
   * applyFilter list elements
   */
  private applyFilter(query: string = null): void {

    let query_string: string;
    if (isNullOrUndefined(query)) {
      query_string = this.buildQueryString();
    } else {
      query_string = query;
    }

    // backup
    this.history_service.backup(query_string);

    // access needed data
    const temp = query_string.split("?");
    const deportations = temp[0].split("&");
    const letter = temp[1].toUpperCase();
    const page = temp[2];

    // get complete data first, filter by starting letter
    const data = this.data_service.getDatabase()["rows"];
    const deportation_filtered = data.filter((item) => {
      for (const dep of deportations) {
        if (item["category"] === dep) {
          return item;
        }
      }
    });

    // filter by deportation
    const letter_filtered = deportation_filtered.filter((item) => {
      if (letter !== "*") {
        if (item["name"].toUpperCase()[0] === letter) {
          return item;
        }
      } else {
        return item;
      }
    });

    // show or hide pagination controls
    if (letter_filtered.length <= this.pagination_config.itemsPerPage) {
      this.show_pagination = false;
    } else {
      this.show_pagination = true;
    }

    this.pagination_config.currentPage = +page;
    this.data = letter_filtered;
  }


  /**
   * build filter query string
   */
  private buildQueryString(): string {

    // collect selected deportations
    const deps = [];
    for (const item of this.deportations) {
      if (item["selected"]) {
        deps.push(item["deportation"]);
        deps.push("&");
      }
    }
    deps.pop();

    // build string
    let query_string = "";
    for (const item of deps) {
      query_string += item;
    }

    // append letter & page
    query_string += "?" + this.selected_letter;
    query_string += "?" + this.pagination_config.currentPage;

    return query_string;
  }


  /**
   * update the list of available starting letters for filtering
   */
  private updateAvailableLetterList(): void {

    let letters: string[] = ["*"];
    const data = this.data_service.getDatabase()["rows"];

    for (const item of data) {
      for (const deportation of this.deportations) {
        if (item["category"] === deportation["deportation"]) {
          if (deportation["selected"]) {
            letters.push(item["name"][0]);
          }
        }
      }
    }

    const final_alphabet = [];
    letters = Array.from(new Set(letters));
    for (const letter of letters) {
      const temp = {};
      temp["letter"] = letter.toUpperCase();
      if (letter === "*") {
        temp["state"] = true;
      } else {
        temp["state"] = false;
      }
      final_alphabet.push(temp);
    }

    // remove duplicates and set global value
    this.starting_letters = final_alphabet;
  }


  /**
   * open detail view
   * @param id
   */
  public openDetailView(id: number) {
    this.router.navigate(["detail", id]);
  }

  /**
   * foo
   * @param event
   */
  public pageChange(event: any): void {
    this.pagination_config.currentPage = event;
    this.history_service.backup(this.buildQueryString());
  }

  /**
   * reset active state for each letter except given one
   */
  private resetLetterStates(except: string): void {
    for (const letter of this.starting_letters) {
      if (letter["letter"] === except) {
        letter["state"] = true;
      } else {
        letter["state"] = false;
      }
    }
  }

}
