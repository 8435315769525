import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {isNullOrUndefined} from "util";

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements AfterViewInit {

  // inputs
  @Input() title: string;
  @Input() amount: number;
  @Input() label: string;
  @Input() state: boolean;

  // outputs
  @Output() onclick = new EventEmitter<boolean>();

  // privates
  private checked = true;

  // view elements
  @ViewChild("checkboxvalue") checkbox_value: ElementRef;

  /**
   * c'tor
   */
  constructor() {
    // if there is no label use the default
    if (isNullOrUndefined(this.label)) {
      this.label = "✓";
    }
  }

  /**
   * after view initialized lifecycle hook
   */
  ngAfterViewInit() {

    // apparently the state becomes a string at some point
    // so first there is a typecheck of this.state
    if (typeof this.state == 'string') {
      if (this.state == 'false') {
        this.checked = false;
        this.state = false;
        this.checkbox_value.nativeElement.className = "checkbox-value-hidden";
      }
    } else if (this.state === false) {
      this.checked = false;
      this.checkbox_value.nativeElement.className = "checkbox-value-hidden";
    }

  }

  /**
   * triggers on click
   */
  public checkboxClicked(): void {

    // change state and update ui
    this.checked = !this.checked;
    if (this.checked) {
      this.checkbox_value.nativeElement.className = "checkbox-value-visible";
    } else {
      this.checkbox_value.nativeElement.className = "checkbox-value-hidden";
    }

    // emit event
    this.onclick.emit(this.checked);
  }

  /**
   * returns current state
   * @returns {boolean}: value whether the checkbox is selected
   */
  public isChecked(): boolean {
    return this.checked;
  }

  /**
   * returns the title
   * @returns {string}: set title
   */
  public getTitle(): string {
    return this.title;
  }


}
