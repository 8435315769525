import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-letter',
  templateUrl: './letter.component.html',
  styleUrls: ['./letter.component.scss']
})
export class LetterComponent implements OnInit, OnChanges {

  // io
  @Input() letter: string;
  @Input() state: boolean;
  @Output() selected = new EventEmitter<string>();

  // privates
  private active = false;

  // view childs
  @ViewChild("button") button: ElementRef;

  /**
   * c'tor
   */
  constructor() {
  }


  ngOnInit() {
    this.initialize();
  }

  ngOnChanges() {
    this.initialize();
  }

  /**
   * initialie letter button
   */
  private initialize(): void {
    if (this.state === true) {
      this.active = true;
      this.button.nativeElement.classList.add("btn-active");
    } else {
      this.active = false;
      this.button.nativeElement.classList.remove("btn-active");
    }

  }

  /**
   * onclick callback
   */
  public clicked(): void {
    this.active = true;
    if (this.active) {
      this.button.nativeElement.classList.add("btn-active");
    } else {
      this.button.nativeElement.classList.remove("btn-active");
    }

    this.selected.emit(this.letter);
  }

  /**
   * state getter
   */
  public getState(): boolean {
    return this.active;
  }

}

