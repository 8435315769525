import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {Params, ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../environments/environment.prod";
import {DataService} from "../data.service";
import {ModalDirective} from "ngx-bootstrap";
import {isNullOrUndefined} from "util";
import {containsElement} from "@angular/animations/browser/src/render/shared";

@Component({
  selector   : 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls  : ['detail.component.scss']
})
export class DetailComponent implements OnInit {

  private data_service: DataService;
  private route: ActivatedRoute;
  private router: Router;

  public data_ready: boolean;
  private selected_id: number;
  public selected_dataset: any;

  public image_path: string = environment.image_base_url;

  @ViewChild('lgModal') private modal_element: ModalDirective;
  @ViewChild('modalimage') private modal_image: ElementRef;

  /**
   * c'tor
   * @param data_service
   * @param route
   * @param r
   */
  constructor(data_service: DataService, route: ActivatedRoute, r: Router) {
    this.data_service = data_service;
    this.route = route;
    this.router = r;
  }

  /**
   * on init lifecycle hook
   */
  ngOnInit() {
    this.route.params.subscribe(() => {
      this.initialize();
    });

  }

  /**
   * initialize component
   */
  private initialize(): void {

    // get the current camp ID by parsing the URL's parameters
    this.route.params.forEach((params: Params) => {
      this.selected_id = +params['id'];
    });

    if (this.data_service.data_ready) {
      this.findCurrentDataset(this.data_service.getDatabase()["rows"]);
    } else {
      this.data_service.onDBLoaded.subscribe(() => {
        this.findCurrentDataset(this.data_service.getDatabase()["rows"]);
      });
    }


  }

  /**
   * find the current dataset
   */
  private findCurrentDataset(data: any[]): void {

    for (const item of data) {
      if (+item["id"] === this.selected_id) {
        this.selected_dataset = item;
        this.data_ready = true;
      }
    }

  }

  /**
   * opens an image
   * @param source: source of the image
   */
  public openImage(source: string) {
    this.modal_image.nativeElement.src = this.image_path + source;
    this.showModal();
  }

  /**
   * show the modal
   */
  private showModal() {
    this.modal_element.show();
  }

  /**
   * check if a property is valid
   * @param dataset
   * @return {boolean}
   */
  public isValid(dataset: any): boolean {
    if (isNullOrUndefined(dataset)) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * check whether the dataset contains
   * @param dataset
   * @returns {boolean}
   */
  public containsPortrait(dataset): boolean {
    if (dataset.length > 0) {
      for (const item of dataset) {
        if (item["category"] == "Portrait") {
          return true;
        }
      }
    }
  }

  /**
   * get the image filename
   * @param dataset
   * @returns {string}
   */
  public getPortraitImage(dataset): string {
    for (const item of dataset) {
      if (item["category"] == "Portrait") {
        console.log(item["filename"]);
        return item["filename"];
      }
    }
  }


}
