import {Directive, ElementRef, Input, OnChanges, OnInit} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';


@Directive({
  selector: '[includeHtml]'
})
export class IncludeHtmlDirective implements OnInit, OnChanges {

  @Input('includeHtml') filename: string;

  private http: HttpClient;
  private element_ref: ElementRef;

  /**
   * c'tor
   * @param {Http} http
   * @param {ElementRef} el
   * usage: <div [includeHtml]="filename"></div>
   */
  constructor(http: HttpClient, el: ElementRef) {
    this.http = http;
    this.element_ref = el;
  }


  /**
   * on init hook initialize
   */
  ngOnInit() {
    this.initialize();
  }


  /**
   * reinitialize on change
   */
  ngOnChanges() {
    this.initialize();
  }


  /**
   * init function
   * request the file and handle the request
   */
  private initialize(): void {
    const request = this.http.get(this.filename, {responseType: 'text'})
      .subscribe(data => {
          this.element_ref.nativeElement.innerHTML = data;
        }, (error: HttpErrorResponse) => {
          console.log(error);
        }
      );
  }

}

