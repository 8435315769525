import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {environment} from '../environments/environment.prod';

@Injectable()
export class DataService {

  // private members
  private base_url: string;
  private database: any;
  private pages: any;

  public data_ready = false;
  public pages_ready = false;

  // public members
  public onDBLoaded = new Subject();
  public onPagesLoaded = new Subject();


  /**
   * c'tor
   * @param http
   */
  constructor(private http: HttpClient) {
    this.http = http;

    // load the database
    this.loadDatabaseFromFile('data.json')
      .subscribe(data => {
        if (data) {
          this.database = data;
          this.onDBLoaded.next();
          this.data_ready = true;
        }

      }, this.logError);

    // load page tree
    this.loadDatabaseFromFile('pages.json')
      .subscribe(data => {
        if (data) {
          this.pages = data;
          this.onPagesLoaded.next();
          this.pages_ready = true;
        }

      }, this.logError);

  }


  /**
   * load data
   * @param filename
   * @returns {Observable<R>}
   */
  private loadDatabaseFromFile(filename: string): Observable<any> {
    const url: string = environment.data_base_url + filename;
    return this.http.get(url);
    // .map(this.extractJsonData)
    // .catch(this.handleError);
  }


  /**
   * load HTML data
   * @param filename
   * @returns {Observable<R>}
   */
  public getHTMLData(filename: string) {
    const url: string = this.base_url + filename;
    return this.http.get(url);
    // .map(this.extractTextData)
    // .catch(this.handleError);
  }


  /**
   * extracts the data from input request
   * @param res
   * @returns {any|{}}
   */
  private extractJsonData(res: Response) {
    return res.json();
  }


  /**
   * extracts the data from input request
   * @param res
   * @returns {any|{}}
   */
  private extractTextData(res: Response): any {
    return res.text();
  }


  /**
   * log Error
   * @param error
   */
  private logError(error: any): void {
    console.log('Error occured: ', error);
  }


  /**
   * error handling function
   * @param error
   * @returns {ErrorObservable}
   */
  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }


  /**
   * returns the database
   * @returns {any}
   */
  public getDatabase(): any {
    return this.database;
  }

  /**
   * returns the pages
   * @returns {any}
   */
  public getPages(): any {
    return this.pages;
  }

}

