import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AlertModule, CollapseModule} from 'ngx-bootstrap';
import {NgxPaginationModule} from 'ngx-pagination';
import {DataService} from './data.service';
import {NavigationComponent} from './navigation/navigation.component';
import {HeadComponent} from './head/head.component';
import {FooterComponent} from './footer/footer.component';
import {SearchComponent} from './search/search.component';
import {ListComponent} from './list/list.component';
import {HistoryService} from './history.service';
import {DetailComponent} from './detail/detail.component';
import {IncludeHtmlDirective} from './include-html.directive';
import {ContentComponent} from './content/content.component';
import {PagesComponent} from './pages/pages.component';
import {CheckboxComponent} from './checkbox/checkbox.component';
import {LetterComponent} from './letter/letter.component';
import {HighlightService} from './highlight.service';
import {HttpClientModule} from '@angular/common/http';


@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    HeadComponent,
    FooterComponent,
    SearchComponent,
    ListComponent,
    DetailComponent,
    IncludeHtmlDirective,
    ContentComponent,
    IncludeHtmlDirective,
    PagesComponent,
    CheckboxComponent,
    LetterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AlertModule.forRoot(),
    NgxPaginationModule,
    CollapseModule
  ],
  providers: [DataService, HistoryService, HighlightService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
